<template>
  <div class="page">
    <Header />
    <div class="container d--sm--flex justify-content--sm--center align-items--sm--center flex-direction--sm--column">
        <div class="h5 text--black font-weight--600 mb--sm--20 text--sm--center">404 Page not found! Nothing here.</div>
        <ConfirmCheckout class="mb--sm--10"/>
        <div class="h4 font--roboto font-weight--400 mb--sm--4 text--sm--center">It looks like nothing was found at this location.</div>
    </div>
    <Footer/>
    <FooterMobile/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'
import ConfirmCheckout from '@/assets/confirm-checkout.svg'
export default {
  components: {
    Header,
    Footer,
    FooterMobile,
    ConfirmCheckout
  },
  data () {
    return {}
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: false,
      headingText: '',
      back: true,
      search: true,
      notify: true,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', false)
  }
}
</script>
